<template>
  <div class="cancellation">
    <div class="title">
      <h1>注销协议</h1>
    </div>
    <p>
      尊敬的用户，在您正式开始下一步有关本游戏的游戏账号注销流程前，我们先为您做出如下特别说明：注销本游戏账号后，除法律法规或本协议另有规定外，您在该游戏账号下的个人信息将进行删除或匿名化处理且此前已关联该游戏账号的相关产品与服务将不再关联。该游戏账号一旦注销完成，将无法恢复，请您谨慎操作。
    </p>

    <p>
      若您经过慎重考虑后仍决定注销本游戏账号的，请您务必先行仔细阅读和充分理解本《游戏账号注销协议》（“本协议”），并同意本协议全部内容，尤其是本协议免责、限制条款及争议解决方式和管辖条款，前述条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，本协议由您与游戏服务提供方（以下称“我们”）共同缔结，本协议具有合同效力。您按照我们的注销操作流程开始注销流程的，或者您勾选本注销协议并开始下一步操作的，均视为您已经同意和遵守本协议全部内容。为确保注销的顺利完成，请您在锁定期内不要使用该账号登录本游戏，否则视为您撤销注销该账号。
    </p>

    <p>
      一、在您的游戏账号注销后，将（可能）产生包括但不限于如下后果，并需要您自行承担：
    </p>
    <p>
      1.1您将无法登录、使用该游戏账号，您的好友无法与您在本游戏内进行互动；
    </p>
    <p>
      1.2
      除法律法规或本协议另有规定外，您该游戏账号下的个人信息将会被删除或匿名化处理。您无法再找回您的个人信息，也无权要求我们找回，前述个人信息包括但不限于：头像、昵称、战绩、充值和消费记录、地理位置、聊天记录等内容。
    </p>

    <p>
      如您的账号曾因违反国家相关法律法规及（或）服务协议相关规定被我们采取封号及（或）其他处理措施的，为了维护您及其他相关权利人的合法权益所必须，在您游戏账号注销后我们可能会对与该账号违规行为有关的游戏数据保留3年。在保留期限内，我们除将该数据用于维护您及其他相关权利人的合法权益外，不会用于其他目的
      。
    </p>

    <p>
      1.3
      您知悉，游戏账号注销的，该游戏的相关游戏收益也将会被删除。据此，也特别提示您，在您注销本游戏账号前请您妥善处理您的游戏收益或相关的游戏收益已结清。一旦您注销本游戏账号，如该游戏账号下还存在游戏收益的（包括在本游戏使用期间已产生的及未来可能产生的游戏收益），视为您自愿放弃该游戏收益，且<span
        class="bold"
        >我们有权对该游戏账号下的全部游戏收益做清除处理，因此产生的后果由您自行承担，您同意不会要求我们恢复或赔偿该游戏收益</span
      >。前述游戏收益包括但不限于：游戏会员权益、等级；与游戏角色成长升级相关的所有数据（包括但不限于经验值、荣誉值、声望值、称号等）；尚未使用的网络游戏虚拟货币、虚拟道具等虚拟物品（如卡券、金币、钻石、道具及其他等）；已经购买的未到期的服务；已产生但未消耗完毕的其他游戏收益或未来预期的游戏收益等。
    </p>

    <p>
      1.4
      <span class="bold">本游戏账号一旦注销，将无法恢复</span
      >。即使您在注销后以相同手机号码、QQ、微信或认可的其他账号作为游戏账号再次向我们申请注册登录本游戏的，此时该账号将视为新的用户账号。因此，我们善意地提醒您在申请注销前对需要备份的内容提前做好备份。
    </p>

    <p>
      1.5
      <span class="bold"
        >本游戏账号一旦注销，您与我们签署的有关本游戏的全部服务协议及其他有关协议将一并终止</span
      >。但该等协议的终止并不影响游戏账号注销前您使用本游戏服务应当承担的相关责任，<span
        class="bold"
        >您仍需要对游戏账号注销前使用本游戏服务的行为承担相应责任，包括但不限于可能产生的违约责任、损害赔偿责任及履约义务等</span
      >。
    </p>

    <p>
      二、在您向我们申请/继续申请注销游戏账号之前，为保障您的账号安全和相关权益，您需先行检查，并确保和承诺您申请注销的游戏账号已经同时满足以下条件，这些条件包括但不限于：
    </p>
    <p>
      2.1该游戏账号为您本人合法拥有并登录使用，且符合服务协议及相关说明规则中有关游戏账号的规定。
    </p>

    <p>
      2.2
      该游戏账号处于安全及正常使用状态，账号为正常使用中且无被盗等风险。且账号未处于违规处理（违规处理指账号因违反该游戏相关协议、规则被采取封号及其他处理措施）期间。
    </p>

    <p>
      2.3
      该游戏账号中的相关游戏收益已结清或得到妥善处理：包括但不限于点券、钻石、金币、道具、体验卡、会员权益及其他游戏收益等。请您妥善处理您的游戏收益，若注销时未处理，视为您自愿放弃该等游戏收益。
    </p>

    <p>
      2.4
      该游戏账号无未完成状态订单：包括但不限于基于该游戏账号产生的有关网络游戏虚拟货币、虚拟道具、周边商品等的订单。
    </p>

    <p>
      2.5
      如存在战队创建者身份、亲密关系及其他互动关系的，确保该等关系已解除：包括但不限于确保情侣、死党、闺蜜、师徒等关系解除，创建战队的用户需解除战队创建者身份。
    </p>

    <p>
      2.6
      该游戏账号无任何纠纷：包括投诉举报、被投诉举报、国家机关调查、仲裁、诉讼等纠纷。
    </p>

    <p>2.7 本游戏官方公布的其他条件。</p>

    <p class="bold">
      上述问题您可首先尝试自行处理，如您无法处理的或在处理过程中有任何疑惑的，可联系游戏客服协助处理。
    </p>

    <p>三、如何注销游戏账号？</p>
    <p>
      3.1
      您需按照我们的官方渠道提供的注销流程和操作指引，在相关功能设置页面进行注销操作。
    </p>

    <p>
      3.2
      您已经同意本注销协议的全部内容；同时，您已经检查与确认满足了本协议第二条的要求。
    </p>

    <p>
      3.3
      在您完成上述第3.2点后，为进一步保障您的相关合法权益，我们有权视情况验证您是否满足本协议第二条规定的注销条件。如我们验证的（验证其中一项或多项注销条件），<span
        class="bold"
        >我们有权根据您的账号安全状态以及您的相关产品与服务的使用情况等综合判断您的账号是否符合该注销条件</span
      >。为保障您的账号安全和财产权益，我们有权进行注销申请的身份验证以判断您是该游戏账号的有权使用人。如我们在综合判断后发现该游戏账号不符合注销条件的，或者身份验证失败的，您的注销可能会失败，您可以按照我们的指引进行后续操作或联系客服协助处理。<span
        class="bold"
        >即使我们判断您符合注销条件的，也是基于您的承诺（即您自身确保游戏账号符合上述注销条件的承诺）进行的形式审查，仍由您自身对游戏账号符合上述注销条件承担保证责任，我们对此不承担相应的法律责任</span
      >。
    </p>

    <p>
      3.4
      我们在您成功完成上述第3.3点的“身份验证”后，视为您已成功向我们提交了账号注销申请，我们会在15天内核实并处理您的注销申请。
    </p>

    <p>
      3.5
      <span class="bold">具体的注销流程化和操作以该游戏官方公布的为准</span
      >。您理解并同意，在您申请注销账号期间，如我们需要对相关交易、投诉或其他与您的账号相关的事项进行核实的，或者我们有其他合理的理由的，我们有权冻结您的该账号注销申请且暂时不为您提供注销服务。
    </p>

    <p>四、其他</p>
    <p>
      4.1
      本协议为《用户协议》（“主协议”）组成部分，本协议未尽事宜适用主协议。本协议与主协议不一致的，以本协议为准。同时，您知悉并同意：即使您该游戏的游戏账号被注销，也并不减轻或免除您应根据相关法律法规、相关协议、规则等（可能）需要承担的相关责任。
    </p>

    <p>4.2 本协议签订地为中华人民共和国广东省深圳市南山区。</p>

    <p>
      4.3
      本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
    </p>

    <p>
      4.4
      若本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
    </p>

    <p>
      4.5
      如在账号注销过程中有任何问题您无法处理的或在处理过程中有任何疑惑的，可联系客服协助处理。
    </p>
  </div>
</template>

<script>
export default {
  name: 'cancellation',
  data () {
    return {}
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.cancellation {
  word-break: break-all;
  word-wrap: break-word;
  padding-bottom: 20px;
  font-family: '微软雅黑';
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
}
</style>
